<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF MEMO NOT YET ACKNOWLEDGE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">Date</th>
            <th class="text-uppercase">Subject</th>
            <th class="text-center text-uppercase">Release By</th>
            <th class="text-center text-uppercase">Grounds</th>
            <th class="text-center text-uppercase">Acknowledge</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data_items" :key="item.id">
            <td>
              {{ item.date_of_release }}
            </td>
            <td>
              {{ item.subject }}
            </td>
            <td class="text-center">
              {{ item.employee_released.last_name+', '+
              item.employee_released.first_name+' '+
              item.employee_released.middle_name}}
            </td>
            <td>
              {{ item.grounds }}
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.is_acknowledge===0"
                class="mr-2"
                color="info"
                @click="acknowledge_memosss(item)"
              >
                {{icons.mdiCheckDecagram }}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-else></v-progress-circular>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {mdiAlertOutline, mdiEyeOffOutline, mdiEyeOutline, mdiCheckDecagram} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      show: false,
      txt: '',

      saving_request: false,
      alert: false,
      alert_message: '',

      old_password: '',
      new_password: '',
      confirm_password: '',
      isPasswordVisible: false,
    }
  }
  export default {
    props: {
      data_items: Array,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiEyeOutline,
          mdiEyeOffOutline,
          mdiCheckDecagram,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'user_id', 'id_no']),
    },
    methods: {
      ...mapActions('authentication', ['logout_credentials']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['update_password']),
      ...mapActions('employee_memo_history', ['acknowledge_memo']),
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      acknowledge_memosss(item) {
        this.saving_request = true
        this.alert = false

        const data = new FormData()
        data.append('id', item.id);
        this.acknowledge_memo(data)
          .then(response => {
            if (response.status === 200) {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
            }
            this.$emit('data', true)
            this.saving_request = false
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving_request = false
          })
      },
    }
  }
</script>
