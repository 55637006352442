var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"greeting-card"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-card-text',[_c('v-img',{staticClass:"app-logo",attrs:{"src":this.position==='ADMIN'?require('@/assets/images/logos/main-logo.png'):
                      (this.position==='TREASURY'?require('@/assets/images/department_logo/treasury.png'):
                      (this.position==='ACCOUNTING'?require('@/assets/images/department_logo/accounting.png'):
                      (this.position==='AMD'?require('@/assets/images/department_logo/amd.png'):
                      (this.position==='PROPERTY CUSTODIAN'?require('@/assets/images/department_logo/prop.png'):
                      (this.position==='HUMAN RESOURCE'?require('@/assets/images/department_logo/hr.png'):
                      (this.position==='BOOKKEEPING'?require('@/assets/images/department_logo/book.png'):
                      (this.position==='CMD'?require('@/assets/images/department_logo/accounting.png'):require('@/assets/images/logos/main-logo.png')))))))),"max-height":"800","alt":"logo","contain":"","eager":""}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.is_change_password),callback:function ($$v) {_vm.is_change_password=$$v},expression:"is_change_password"}},[_c('ChangePassword')],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.is_need_acknowledge_memo),callback:function ($$v) {_vm.is_need_acknowledge_memo=$$v},expression:"is_need_acknowledge_memo"}},[_c('ListOfMemosNotYetAcknowledge',{attrs:{"data_items":_vm.data_items_memo},on:{"data":_vm.list_of_tobe_akcnowledge_memo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }